/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function SnipcartAddToCart({
  name,
  id,
  price,
  productOptions,
  imageUrl,
  slug,
  blurb,
  weight,
  requiresText,
}) {
  const basePrice = price / 100;
  const snipcartOptions = {};

  // if the product has options, then we need to loop over them, turn them into the correct syntax for snipcart and add them to the button
  if (productOptions) {
    productOptions.forEach((singleOption, index) => {
      const optionChoice = singleOption.option
        .map((option) => `${option.title}[+${option.price / 100}]`)
        .join('|');

      snipcartOptions[`data-item-custom${index + 1}-name`] = singleOption.title;

      snipcartOptions[`data-item-custom${index + 1}-options`] = optionChoice;

      snipcartOptions[`data-item-custom${index + 1}-required`] = 'true';
    });
  }

  // If the product required custom input from the customer,  This will add it to the checkout
  if (requiresText) {
    const optionsLength = Object.keys(snipcartOptions).length / 3;

    snipcartOptions[`data-item-custom${optionsLength + 1}-name`] =
      'Please enter the custom label you would like';

    snipcartOptions[`data-item-custom${optionsLength + 1}-type`] = 'textarea';

    snipcartOptions[`data-item-custom${optionsLength + 1}-required`] = true;
  }

  return (
    <button
      type="button"
      className="snipcart-add-item"
      data-item-id={id}
      data-item-price={basePrice}
      data-item-url={`/${slug}`}
      data-item-image={imageUrl}
      data-item-description={blurb}
      data-item-name={name}
      data-item-weight={weight}
      {...snipcartOptions}
    >
      Add To Cart
    </button>
  );
}

SnipcartAddToCart.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  price: PropTypes.any.isRequired,
  productOptions: PropTypes.array.isRequired,
  imageUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  requiresText: PropTypes.bool,
};
