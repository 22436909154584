import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import styled from 'styled-components';
import formatMoney from '../utils/formatMoney';

import Breadcrumb from '../components/Layout/Breadcrumb';
import SnipcartAddToCart from '../components/SnipcartAddToCart';
import SEO from '../components/Layout/SEO';

export const query = graphql`
  query ($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      title
      price
      weight
      requiresText

      body {
        _rawEn
      }

      blurb

      slug {
        current
      }

      category {
        title
        slug {
          current
        }
      }

      options {
        id
        title
        option {
          _key
          title
          price
        }
      }

      mainImage {
        asset {
          url
          gatsbyImageData(formats: AUTO, width: 1000, height: 1000)
        }
      }
    }
  }
`;

const ProductPageStyle = styled.div`
  .product-description {
    padding: 0 0 60px 0;
  }
`;

const ProductTop = styled.div`
  padding: 60px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 4rem;
  justify-content: space-between;
  max-width: 100%;
  overflow: none;

  @media only screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }

  h2 {
    margin-top: 0;
  }

  button {
    padding: 1rem 2rem;
    background: #bda770;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: var(--headingColor);
    }
  }
`;

export default function ProductPage({ location, data: { product } }) {
  const image = getImage(product.mainImage.asset);

  return (
    <ProductPageStyle>
      <SEO
        title={product.title}
        location={location.href}
        image={product.mainImage.asset.url}
        description={product.blurb}
      />
      <Breadcrumb>
        <Breadcrumb>
          <div className="container">
            <Link to="/">Home</Link>/
            <Link to={`/${product.category.slug.current}`}>
              {product.category.title}
            </Link>
            /<Link to={`/${product.slug.current}`}>{product.title}</Link>
          </div>
        </Breadcrumb>
      </Breadcrumb>

      <div className="container">
        <ProductTop>
          <div className="product-image">
            <GatsbyImage image={image} alt={product.title} />
          </div>

          <div className="product-data">
            <h2>{product.title}</h2>
            <h3>{formatMoney(product.price)}</h3>
            <p>{product.blurb}</p>

            {/* this is the add to cart button */}
            <SnipcartAddToCart
              name={product.title}
              id={product.id}
              price={product.price}
              slug={product.slug.current}
              imageUrl={product.mainImage.asset.url}
              blurb={product.blurb}
              productOptions={product.options}
              weight={product.weight || 0}
              requiresText={product.requiresText}
            />
          </div>
        </ProductTop>
        <hr />
      </div>

      <div className="product-description">
        <div className="containerSmaller">
          <h2>Description</h2>
          <PortableText content={product.body._rawEn} />
        </div>
      </div>
    </ProductPageStyle>
  );
}

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

// TO DO

// create a product image component to display the main image and any additional images
